import React from "react"
import { Helmet } from "react-helmet"
import Logo from "../images/logo.svg"

const logoStyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
}

export default () =>
    <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Australian Software Advisers</title>
        </Helmet>
        <img src={Logo} alt="Australian Software Advisers" style={logoStyle} />
    </div>
